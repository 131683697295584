// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation WebAuthnRegister(
    $data: WebAuthnAttestationInput!,
    $email: String!,
    $token: String!
  ) {
    token: webauthnRegister(
      data: $data,
      email: $email,
      token: $token
    )
  }`;

export default MUTATION;
